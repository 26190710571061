import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import VueTheMask from 'vue-the-mask'
import BootstrapVue from 'bootstrap-vue'

Vue.use(VueTheMask)
Vue.use(BootstrapVue)
Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [],
});

new Vue({
  router,
  el: '#app',
  render: h => h(App),
});
