<template>
  <div id="app">

    <br/>
    <div class="container">
      <Navbar/>
    </div>
    <br/>

    <hr class="hr"/>
    <br/>

    <div class="main-container">
      <Form/>
    </div>
  </div>

</template>

<script>
import Navbar from './components/Navbar.vue'
import Form from './components/Form.vue'

export default {
  name: 'app',
  components: {
    Navbar,
    Form,
  },
  data() {
    return {
      loading: true,
      showModal: false,
      alert: {
        title: '',
        detail: '',
      },
    }
  },
}
</script>

<style scoped>
.hr {
  background-color: #E6CE9E;
  margin: 0;
  height: 5px;
}
.centered {
  margin-top: 20px;
  text-align: center;
}
</style>
