<template>
  <div>
    <b-img center alt="K-9 Logo"
      src="https://res.cloudinary.com/dbl4yeqf9/image/upload/v1676427443/logos/logo_corp_fc_egpbiw.png"
      width="150" height="140">
    </b-img>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
}
</script>
